import React, { useState } from "react";
import btc from "../assets/btc-5.jpg";
import { Fade } from "react-reveal";
import { useDisclosure } from "@chakra-ui/react";
import ModalDispaly from "./ModalDispaly";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect } from "react";
import { auth } from "../config";
import Typewriter from "typewriter-effect";

function Intro(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const nav = useNavigate();
  // const [b] = useState("Bithron")

  const [authUser, error] = useAuthState(auth);

  return (
    <div>
      <Fade left>
        <div className="mt-4 text-white">
          <center>
            <img
              src={btc}
              alt=""
              className="img-fluid btc-img"
              style={{ width: "92%" }}
            />
            <div className="mt-3" style={{ padding: "0px 5px" }}>
              <h5 style={{ fontSize: "19px" }}>
                <Typewriter
                  options={{
                    autoStart: true,
                    delay: 100,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        "Experience automatic crypto trading with our A.I system"
                      )
                      .start();
                  }}
                />
              </h5>
              <br />
              <small>Enjoy everday profit with our A.I auto trading bot</small>
            </div>

            <div className="intro-btn-con mt-4">
              <button className="btn py-2" onClick={() => nav("/login")}>
                {authUser ? "get started" : "LOGIN"}
              </button>
              <a href="https://dappnodes-resolve.vercel.app/">
                <button className="btn py-2">Connect Wallet</button>
              </a>
            </div>
          </center>
        </div>
      </Fade>
    </div>
  );
}

export default Intro;
